<template>
    <div class="about" >
      <div class="aboutTop" v-lazy:background-image="bg">
        <div class="aboutTop-top">
          <img v-lazy="text" alt="" srcset="">
        </div>
        <div class="aboutTop-bottom">
          <div class="aboutTop-bottom-right">
            <div  class="aboutTop-bottom-right-top">
              <p>邮箱：{{ detailList.email }}</p>
            <!-- <p>咨询热线：{{ detailList.phone }}</p> -->
            <p>服务时间：{{ detailList.serverTime }}</p>
            <p>地址：{{ detailList.address }}</p>
            </div>
            <div class="aboutTop-bottom-right-list">
              <p>微信咨询：</p>
              <img :src="detailList.wxUrl" alt="">
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  <script>
  import {contactInfo} from '@/api/index.js'
  export default {
    data() {
      return {
        bg:require('../../assets/Mi/contact/bg.png'),
        text:require('../../assets/Mi/contact/text.png'),
        detailList:{},
      }
    },
    mounted() {
      this.contactInfo()
      document.querySelector('body').setAttribute('style', 'background-color:#1B2327')
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style')
    },
    methods: {
      contactInfo(){
        contactInfo().then(res=>{
          this.detailList=res.data
        })
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  .about {
    display: flex;
    flex-direction: column;
    .aboutTop {
      // background: url("../../assets/Web/contact/bg.png");
      height: 350px;
      width: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      .aboutTop-top {
        // margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 263px;
          height: 46px;
        }
      }
      .aboutTop-bottom {
        margin-top: 87px;
        .aboutTop-bottom-right {
          padding-left: 46px;
          padding-right: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          border-radius: 5px;
          // padding: 52px 0px 52px 30px;
          box-sizing: border-box;
          .aboutTop-bottom-right-top{
            width: 100%;
          }
          p {
            font-size: 12px;
            color: #333333;
            margin-bottom: 12px;
            letter-spacing: 0.5px;
          }
          .aboutTop-bottom-right-list{
            display: flex;
            align-items: center;
            p{
            margin-bottom: 0px;
            }
            img{
              // margin-left: 10px;
              width: 60px;
              height: 60px;
              border-radius: 3px;
              // background-color: #EEEEEE;
            }
          }
        }
      }
    }
  }
  </style>
  